import LawIcon from "~/components/icons/bookshelf/LawIcon.vue";
import ChronikIcon from "~/components/icons/bookshelf/ChronikIcon.vue";
import UserContentIcon from "~/components/icons/bookshelf/UserContentIcon.vue";

export function useLibraryTabs() {
  type ITab = {
    title: string;
    href: string;
    icon: any;
  };

  const { t } = useI18n();

  const tabs = [
    {
      title: t("bookshelf.tabs.laws"),
      href: "/bibliothek/gesetze",
      icon: LawIcon,
    },
    {
      title: t("bookshelf.tabs.content"),
      href: "/bibliothek/inhalte",
      icon: UserContentIcon,
    },
    {
      title: t("bookshelf.tabs.chronic"),
      href: "/bibliothek/chronik",
      icon: ChronikIcon,
    },
  ] satisfies ITab[];

  return tabs;
}
