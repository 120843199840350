<template>
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 158.01 159"
  >
    <g class="cls-4">
      <g>
        <path
          class="cls-3"
          d="m17.57,38.52C40.36,4.82,86.34-4.15,120.26,18.49c33.93,22.63,42.95,68.3,20.17,101.99-22.79,33.7-68.76,42.67-102.69,20.03C17.49,127,5.24,104.45,5,80.23"
        />
        <path class="cls-3" d="m13,41.5V16" />
        <path class="cls-3" d="m13,42h25.5" />
        <path class="cls-2" d="m81.5,78.93v-32.43" />
        <path class="cls-2" d="m49.5,107l32.41-26.5" />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1,
.cls-2,
.cls-3 {
  fill: none;
}

.cls-2 {
  stroke-width: 11.33px;
}

.cls-2,
.cls-3 {
  stroke-linecap: round;
  stroke-miterlimit: 8;
}

.cls-4 {
  clip-path: url(#clippath);
}

.cls-3 {
  stroke-width: 12px;
}
</style>
