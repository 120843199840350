<template>
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 159 158.41"
  >
    <g class="cls-2">
      <g>
        <path
          d="m79.5,158C36.21,158,1,122.79,1,79.5S36.21,1,79.5,1s78.5,35.21,78.5,78.5-35.21,78.5-78.5,78.5Zm0-147C41.73,11,11,41.73,11,79.5s30.73,68.5,68.5,68.5,68.5-30.73,68.5-68.5S117.27,11,79.5,11Z"
        />
        <path
          d="m98.22,50.12l-11.82,1.25c-.11-2.12-.74-3.74-1.87-4.86s-2.7-1.68-4.7-1.68c-1.86,0-3.28.41-4.25,1.22-.97.82-1.46,1.74-1.46,2.77,0,1.4.66,2.75,1.98,4.04.89.86,3.82,2.89,8.81,6.1,4.98,3.21,8.28,5.5,9.88,6.88,2.49,2.09,4.28,4.16,5.35,6.21,1.07,2.05,1.61,4.32,1.61,6.81,0,2.66-.65,5.12-1.96,7.37-1.3,2.25-3.26,4.29-5.87,6.12,1.75,1.6,3.05,3.32,3.91,5.13.86,1.82,1.29,3.75,1.29,5.78,0,4.24-1.59,7.79-4.77,10.66-3.18,2.86-7.75,4.3-13.71,4.3s-10.31-1.42-13.75-4.25-5.47-6.62-6.1-11.34l11.77-1.42c.52,2.58,1.5,4.52,2.94,5.82,1.45,1.3,3.27,1.96,5.48,1.96,1.89,0,3.39-.49,4.51-1.48s1.68-2.18,1.68-3.59-.53-2.55-1.59-3.61c-1.09-1.06-4.91-3.67-11.45-7.84-6.55-4.17-10.81-7.63-12.78-10.38s-2.96-5.83-2.96-9.24c0-2.81.64-5.41,1.93-7.82s3.29-4.38,6.02-5.93c-1.43-1.55-2.51-3.12-3.24-4.73-.73-1.6-1.1-3.24-1.1-4.9,0-3.87,1.51-7.14,4.53-9.82,3.02-2.68,7.27-4.02,12.74-4.02s10.03,1.34,13.26,4.02c3.22,2.68,5.12,6.17,5.69,10.46h0Zm-10.79,37.01c1.26-1.15,2.2-2.24,2.81-3.29.62-1.04.92-2.05.92-3.03s-.33-1.92-.99-2.92c-.66-1-2.46-2.58-5.41-4.73l-11.73-8.51c-.97.57-1.83,1.39-2.58,2.45s-1.12,2.19-1.12,3.39c0,2.89,1.93,5.64,5.8,8.25l12.29,8.38h.01Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-2 {
  clip-path: url(#clippath);
}
</style>
