<template>
  <svg
    width="410"
    height="410"
    viewBox="0 0 410 410"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M195.242 87C181.949 87 171.093 94.3408 170.413 103.576H157.488V112.783H143C122.013 112.783 105 129.796 105 150.783V270C105 290.987 122.013 308 143 308H195.242V285H143C134.716 285 128 278.284 128 270V150.783C128 142.498 134.716 135.783 143 135.783H157.488V139.488H195.242L195.242 87Z"
    />
    <path d="M195.242 165.27H169.459V181.845H195.242V165.27Z" />
    <path d="M195.242 196.579H169.459V213.154H195.242V196.579Z" />
    <path d="M195.242 227.889H169.459V244.464H195.242V227.889Z" />
    <path
      d="M157.487 173.557C157.487 179.152 152.952 183.687 147.358 183.687C141.764 183.687 137.229 179.152 137.229 173.557C137.229 167.963 141.764 163.428 147.358 163.428C152.952 163.428 157.487 167.963 157.487 173.557Z"
    />
    <path
      d="M147.358 214.995C152.952 214.995 157.487 210.46 157.487 204.866C157.487 199.272 152.952 194.737 147.358 194.737C141.764 194.737 137.229 199.272 137.229 204.866C137.229 210.46 141.764 214.995 147.358 214.995Z"
    />
    <path
      d="M147.358 246.305C152.952 246.305 157.487 241.77 157.487 236.176C157.487 230.582 152.952 226.047 147.358 226.047C141.764 226.047 137.229 230.582 137.229 236.176C137.229 241.77 141.764 246.305 147.358 246.305Z"
    />
    <path
      d="M312.5 151L312.5 229.815L234.316 307.999L215 308V285H229.257V247.296C229.257 234.594 239.554 224.296 252.257 224.296H289.5V151C289.5 142.716 282.784 136 274.5 136H215V113H274.5C295.487 113 312.5 130.013 312.5 151Z"
    />
    <path
      d="M410 205C410 318.218 318.218 410 205 410C91.7816 410 0 318.218 0 205C0 91.7816 91.7816 0 205 0C318.218 0 410 91.7816 410 205ZM23.7283 205C23.7283 305.114 104.886 386.272 205 386.272C305.114 386.272 386.272 305.114 386.272 205C386.272 104.886 305.114 23.7283 205 23.7283C104.886 23.7283 23.7283 104.886 23.7283 205Z"
    />
  </svg>
</template>

<style scoped>
.cls-2 {
  clip-path: url(#clippath);
}
</style>
